@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?xp5n8d');
  src: url('fonts/icomoon.eot?xp5n8d#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?xp5n8d') format('truetype'), url('fonts/icomoon.woff?xp5n8d') format('woff'),
    url('fonts/icomoon.svg?xp5n8d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-warning:before {
  content: '\e953';
}
.icon-chart:before {
  content: '\e954';
}
.icon-target:before {
  content: '\e955';
}
.icon-cone:before {
  content: '\e957';
}
.icon-flag:before {
  content: '\e95e';
}
.icon-list2:before {
  content: '\e959';
}
.icon-grid:before {
  content: '\e95b';
}
.icon-target1:before {
  content: '\e95c';
}
.icon-movie:before {
  content: '\e930';
}
.icon-plus-alt:before {
  content: '\e911';
}
.icon-pin:before {
  content: '\e931';
}
.icon-bolt:before {
  content: '\e910';
}
.icon-key-stroke:before {
  content: '\e932';
}
.icon-key-fill:before {
  content: '\e933';
}
.icon-new-window:before {
  content: '\e934';
}
.icon-reload:before {
  content: '\e936';
}
.icon-play1:before {
  content: '\e946';
}
.icon-at:before {
  content: '\e923';
}
.icon-list1:before {
  content: '\e924';
}
.icon-brush:before {
  content: '\e915';
}
.icon-layers-alt:before {
  content: '\e925';
}
.icon-layers1:before {
  content: '\e928';
}
.icon-eye1:before {
  content: '\e90f';
}
.icon-user2:before {
  content: '\e91a';
}
.icon-loop:before {
  content: '\e903';
}
.icon-home:before {
  content: '\e900';
}
.icon-eyedropper:before {
  content: '\e90a';
}
.icon-droplet:before {
  content: '\e90b';
}
.icon-paint-format:before {
  content: '\e90c';
}
.icon-image:before {
  content: '\e90d';
}
.icon-images:before {
  content: '\e90e';
}
.icon-play:before {
  content: '\e912';
}
.icon-film:before {
  content: '\e913';
}
.icon-video-camera:before {
  content: '\e914';
}
.icon-books:before {
  content: '\e920';
}
.icon-file-text2:before {
  content: '\e926';
}
.icon-file-picture:before {
  content: '\e927';
}
.icon-file-video:before {
  content: '\e92a';
}
.icon-copy1:before {
  content: '\e937';
}
.icon-paste:before {
  content: '\e938';
}
.icon-stack:before {
  content: '\e93a';
}
.icon-price-tag:before {
  content: '\e935';
}
.icon-price-tags:before {
  content: '\e93b';
}
.icon-ticket:before {
  content: '\e939';
}
.icon-cart:before {
  content: '\e93c';
}
.icon-display:before {
  content: '\e956';
}
.icon-mobile:before {
  content: '\e958';
}
.icon-tablet:before {
  content: '\e95a';
}
.icon-floppy-disk:before {
  content: '\e962';
}
.icon-undo:before {
  content: '\e965';
}
.icon-redo:before {
  content: '\e966';
}
.icon-undo2:before {
  content: '\e967';
}
.icon-redo2:before {
  content: '\e968';
}
.icon-user:before {
  content: '\e971';
}
.icon-users:before {
  content: '\e972';
}
.icon-user-tie:before {
  content: '\e976';
}
.icon-search:before {
  content: '\e986';
}
.icon-zoom-in:before {
  content: '\e987';
}
.icon-zoom-out:before {
  content: '\e988';
}
.icon-enlarge1:before {
  content: '\e989';
}
.icon-shrink:before {
  content: '\e98a';
}
.icon-enlarge2:before {
  content: '\e98b';
}
.icon-shrink2:before {
  content: '\e98c';
}
.icon-key:before {
  content: '\e98d';
}
.icon-equalizer:before {
  content: '\e992';
}
.icon-equalizer2:before {
  content: '\e993';
}
.icon-cogs:before {
  content: '\e995';
}
.icon-bin:before {
  content: '\e9ac';
}
.icon-bin2:before {
  content: '\e9ad';
}
.icon-power-cord:before {
  content: '\e9b7';
}
.icon-menu:before {
  content: '\e9bd';
}
.icon-menu3:before {
  content: '\e9bf';
}
.icon-menu4:before {
  content: '\e9c0';
}
.icon-earth:before {
  content: '\e9ca';
}
.icon-eye2:before {
  content: '\e9ce';
}
.icon-plus:before {
  content: '\ea0a';
}
.icon-cross:before {
  content: '\ea0f';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-arrow-up-left:before {
  content: '\ea31';
}
.icon-arrow-up:before {
  content: '\ea32';
}
.icon-arrow-up-right:before {
  content: '\ea33';
}
.icon-arrow-right:before {
  content: '\ea34';
}
.icon-arrow-down-right:before {
  content: '\ea35';
}
.icon-arrow-down:before {
  content: '\ea36';
}
.icon-arrow-down-left:before {
  content: '\ea37';
}
.icon-arrow-left:before {
  content: '\ea38';
}
.icon-arrow-up-left2:before {
  content: '\ea39';
}
.icon-arrow-up2:before {
  content: '\ea3a';
}
.icon-arrow-up-right2:before {
  content: '\ea3b';
}
.icon-arrow-right2:before {
  content: '\ea3c';
}
.icon-arrow-down-right2:before {
  content: '\ea3d';
}
.icon-arrow-down2:before {
  content: '\ea3e';
}
.icon-arrow-down-left2:before {
  content: '\ea3f';
}
.icon-arrow-left2:before {
  content: '\ea40';
}
.icon-text-height1:before {
  content: '\ea5f';
}
.icon-text-width1:before {
  content: '\ea60';
}
.icon-font-size:before {
  content: '\ea61';
}
.icon-text-color:before {
  content: '\ea6d';
}
.icon-insert-template:before {
  content: '\ea72';
}
.icon-paragraph-left:before {
  content: '\ea77';
}
.icon-paragraph-center:before {
  content: '\ea78';
}
.icon-paragraph-right:before {
  content: '\ea79';
}
.icon-paragraph-justify:before {
  content: '\ea7a';
}
.icon-indent-increase:before {
  content: '\ea7b';
}
.icon-indent-decrease:before {
  content: '\ea7c';
}
.icon-share:before {
  content: '\ea7d';
}
.icon-new-tab:before {
  content: '\ea7e';
}
.icon-embed2:before {
  content: '\ea80';
}
.icon-google:before {
  content: '\ea88';
}
.icon-google2:before {
  content: '\ea89';
}
.icon-users2:before {
  content: '\e973';
}
.icon-rule:before {
  content: '\e95d';
}
.icon-check-square:before {
  content: '\e93d';
}
.icon-copy:before {
  content: '\e929';
}
.icon-crop:before {
  content: '\e92b';
}
.icon-layers:before {
  content: '\e905';
}
.icon-save1:before {
  content: '\e908';
}
.icon-trash-2:before {
  content: '\e92c';
}
.icon-type:before {
  content: '\e92d';
}
.icon-rotate_right:before {
  content: '\e902';
}
.icon-check-circle:before {
  content: '\e949';
}
.icon-check-square1:before {
  content: '\e94a';
}
.icon-copy2:before {
  content: '\e94b';
}
.icon-crosshair:before {
  content: '\e94c';
}
.icon-external-link:before {
  content: '\e94d';
}
.icon-play2:before {
  content: '\e94e';
}
.icon-play-circle1:before {
  content: '\e94f';
}
.icon-refresh-ccw:before {
  content: '\e950';
}
.icon-browser:before {
  content: '\e00c';
}
.icon-video:before {
  content: '\e011';
}
.icon-puzzle:before {
  content: '\e026';
}
.icon-tools:before {
  content: '\e033';
}
.icon-paintbrush:before {
  content: '\e036';
}
.icon-globe:before {
  content: '\e045';
}
.icon-genius:before {
  content: '\e046';
}
.icon-document-star:before {
  content: '\e906';
}
.icon-user3:before {
  content: '\e91b';
}
.icon-resize:before {
  content: '\e904';
}
.icon-responsive:before {
  content: '\e904';
}
.icon-maximize:before {
  content: '\e904';
}
.icon-enlarge:before {
  content: '\e904';
}
.icon-align-center1:before {
  content: '\e93e';
}
.icon-align-justified:before {
  content: '\e93f';
}
.icon-align-left1:before {
  content: '\e940';
}
.icon-align-right1:before {
  content: '\e941';
}
.icon-format-font-size:before {
  content: '\e922';
}
.icon-list-add:before {
  content: '\e942';
}
.icon-plugin:before {
  content: '\e919';
}
.icon-save-disk:before {
  content: '\e909';
}
.icon-search1:before {
  content: '\e943';
}
.icon-user4:before {
  content: '\e91c';
}
.icon-user-solid-circle:before {
  content: '\e91d';
}
.icon-user-solid-square:before {
  content: '\e91e';
}
.icon-chevron-left:before {
  content: '\e947';
}
.icon-chevron-right:before {
  content: '\e948';
}
.icon-upload-to-cloud:before {
  content: '\e944';
}
.icon-upload:before {
  content: '\e945';
}
.icon-archive:before {
  content: '\e907';
}
.icon-brush1:before {
  content: '\e916';
}
.icon-colours:before {
  content: '\e92e';
}
.icon-flat-brush:before {
  content: '\e917';
}
.icon-help-with-circle:before {
  content: '\e91f';
}
.icon-images1:before {
  content: '\e92f';
}
.icon-lifebuoy:before {
  content: '\e921';
}
.icon-palette:before {
  content: '\e901';
}
.icon-round-brush:before {
  content: '\e918';
}
.icon-upload-to-cloud1:before {
  content: '\e951';
}
.icon-upload1:before {
  content: '\e952';
}
.icon-star:before {
  content: '\f005';
}
.icon-star-o:before {
  content: '\f006';
}
.icon-user1:before {
  content: '\f007';
}
.icon-film1:before {
  content: '\f008';
}
.icon-close:before {
  content: '\f00d';
}
.icon-remove:before {
  content: '\f00d';
}
.icon-times:before {
  content: '\f00d';
}
.icon-search-plus:before {
  content: '\f00e';
}
.icon-search-minus:before {
  content: '\f010';
}
.icon-cog:before {
  content: '\f013';
}
.icon-gear:before {
  content: '\f013';
}
.icon-trash-o:before {
  content: '\f014';
}
.icon-home1:before {
  content: '\f015';
}
.icon-repeat:before {
  content: '\f01e';
}
.icon-rotate-right:before {
  content: '\f01e';
}
.icon-camera:before {
  content: '\f030';
}
.icon-font:before {
  content: '\f031';
}
.icon-bold:before {
  content: '\f032';
}
.icon-text-height:before {
  content: '\f034';
}
.icon-text-width:before {
  content: '\f035';
}
.icon-align-left:before {
  content: '\f036';
}
.icon-align-center:before {
  content: '\f037';
}
.icon-align-right:before {
  content: '\f038';
}
.icon-align-justify:before {
  content: '\f039';
}
.icon-list:before {
  content: '\f03a';
}
.icon-dedent:before {
  content: '\f03b';
}
.icon-outdent:before {
  content: '\f03b';
}
.icon-indent:before {
  content: '\f03c';
}
.icon-video-camera1:before {
  content: '\f03d';
}
.icon-image1:before {
  content: '\f03e';
}
.icon-photo:before {
  content: '\f03e';
}
.icon-picture-o:before {
  content: '\f03e';
}
.icon-pencil:before {
  content: '\f040';
}
.icon-edit:before {
  content: '\f044';
}
.icon-pencil-square-o:before {
  content: '\f044';
}
.icon-check-square-o:before {
  content: '\f046';
}
.icon-arrows:before {
  content: '\f047';
}
.icon-plus-circle:before {
  content: '\f055';
}
.icon-exclamation-circle:before {
  content: '\f06a';
}
.icon-eye:before {
  content: '\f06e';
}
.icon-eye-slash:before {
  content: '\f070';
}
.icon-calendar:before {
  content: '\f073';
}
.icon-arrows-v:before {
  content: '\f07d';
}
.icon-arrows-h:before {
  content: '\f07e';
}
.icon-camera-retro:before {
  content: '\f083';
}
.icon-sign-out:before {
  content: '\f08b';
}
.icon-globe1:before {
  content: '\f0ac';
}
.icon-group:before {
  content: '\f0c0';
}
.icon-users1:before {
  content: '\f0c0';
}
.icon-chain:before {
  content: '\f0c1';
}
.icon-link:before {
  content: '\f0c1';
}
.icon-floppy-o:before {
  content: '\f0c7';
}
.icon-save:before {
  content: '\f0c7';
}
.icon-list-ul:before {
  content: '\f0ca';
}
.icon-list-ol:before {
  content: '\f0cb';
}
.icon-underline:before {
  content: '\f0cd';
}
.icon-rotate-left:before {
  content: '\f0e2';
}
.icon-undo1:before {
  content: '\f0e2';
}
.icon-bolt1:before {
  content: '\f0e7';
}
.icon-flash:before {
  content: '\f0e7';
}
.icon-sitemap:before {
  content: '\f0e8';
}
.icon-plus-square:before {
  content: '\f0fe';
}
.icon-play-circle:before {
  content: '\f144';
}
.icon-level-up:before {
  content: '\f148';
}
.icon-level-down:before {
  content: '\f149';
}
.icon-sort-alpha-asc:before {
  content: '\f15d';
}
.icon-sort-alpha-desc:before {
  content: '\f15e';
}
.icon-sort-amount-asc:before {
  content: '\f160';
}
.icon-sort-amount-desc:before {
  content: '\f161';
}
.icon-sort-numeric-asc:before {
  content: '\f162';
}
.icon-sort-numeric-desc:before {
  content: '\f163';
}
.icon-youtube-play:before {
  content: '\f16a';
}
.icon-plus-square-o:before {
  content: '\f196';
}
.icon-language:before {
  content: '\f1ab';
}
.icon-file-image-o:before {
  content: '\f1c5';
}
.icon-file-photo-o:before {
  content: '\f1c5';
}
.icon-file-picture-o:before {
  content: '\f1c5';
}
.icon-life-bouy:before {
  content: '\f1cd';
}
.icon-life-buoy:before {
  content: '\f1cd';
}
.icon-life-ring:before {
  content: '\f1cd';
}
.icon-life-saver:before {
  content: '\f1cd';
}
.icon-support:before {
  content: '\f1cd';
}
.icon-trash:before {
  content: '\f1f8';
}
.icon-paint-brush:before {
  content: '\f1fc';
}
.icon-television:before {
  content: '\f26c';
}
.icon-tv:before {
  content: '\f26c';
}
.icon-calendar-plus-o:before {
  content: '\f271';
}
.icon-user-circle:before {
  content: '\f2bd';
}
.icon-times-rectangle:before {
  content: '\f2d3';
}
.icon-window-close:before {
  content: '\f2d3';
}
.icon-times-rectangle-o:before {
  content: '\f2d4';
}
.icon-window-close-o:before {
  content: '\f2d4';
}
.icon-check:before {
  content: '\f00c';
}
.icon-close1:before {
  content: '\f00f';
}
.icon-remove1:before {
  content: '\f00f';
}
.icon-times1:before {
  content: '\f00f';
}
.icon-lock:before {
  content: '\f023';
}
.icon-tag:before {
  content: '\f02b';
}
.icon-tags:before {
  content: '\f02c';
}
.icon-random:before {
  content: '\f074';
}
.icon-cloud-download:before {
  content: '\f0ed';
}
.icon-cloud-upload:before {
  content: '\f0ee';
}
.icon-plus-square1:before {
  content: '\f0ff';
}
.icon-minus-square:before {
  content: '\f146';
}
.icon-minus-square-o:before {
  content: '\f147';
}
.icon-plus-square-o1:before {
  content: '\f197';
}
.icon-database:before {
  content: '\f1c0';
}
.icon-chain1:before {
  content: '\f0c2';
}
.icon-link1:before {
  content: '\f0c2';
}

.gui-structure .gui-structure-header {
  height: unset !important;
}
