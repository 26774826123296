/* latin */
@font-face {
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  src: local('ABeeZee Regular'), local('ABeeZee-Regular'), url(https://fonts.gstatic.com/s/abeezee/v12/esDR31xSG-6AGleN2tWkkA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  src: local('Abel Regular'), local('Abel-Regular'), url(https://fonts.gstatic.com/s/abel/v9/MwQ5bhbm2POE2V9BPQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* sinhala */
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  src: local('Abhaya Libre Regular'), local('AbhayaLibre-Regular'), url(https://fonts.gstatic.com/s/abhayalibre/v4/e3tmeuGtX-Co5MNzeAOqinEQYUnXkvc.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0D82-0DF4, U+200C-200D, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  src: local('Abhaya Libre Regular'), local('AbhayaLibre-Regular'), url(https://fonts.gstatic.com/s/abhayalibre/v4/e3tmeuGtX-Co5MNzeAOqinEQcknXkvc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  src: local('Abhaya Libre Regular'), local('AbhayaLibre-Regular'), url(https://fonts.gstatic.com/s/abhayalibre/v4/e3tmeuGtX-Co5MNzeAOqinEQfEnX.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
