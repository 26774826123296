@import './_variables';
@import './fonts.scss';
@import './mixins';
@import './public.scss';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

a:focus,
textarea:focus,
select:focus {
  outline: none;
}

.cke_top {
  background-color: $color-brown !important;
  border-bottom-color: $color-brown !important;
  color: $color-graywhite !important;
}

/* You can add global styles to this file, and also import other style files */

.white {
  color: white !important;
}

.pt50 {
  padding-top: 50px;
}
.pt20 {
  padding-top: 20px;
}

.p10 {
  padding: 10px;
}

.m10 {
  margin: 10px;
}
.mt10 {
  margin-top: 10px;
}

.fill {
  height: 100%;
  width: 100%;
}

.nohor {
  overflow-x: hidden;
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button {
  width: 25px;
  height: 25px;
}
::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border: 24px none #c5c5c5;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}
::-webkit-scrollbar-thumb:active {
  background: $color-brown;
}
::-webkit-scrollbar-track {
  background: $color-graywhite;
  border: 23px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: $color-graywhite;
}
::-webkit-scrollbar-track:active {
  background: $color-graywhite;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.global-item-side {
  position: absolute;
  right: 3px;
  top: 3px;
  color: $color-lightblue2;
}

.cell-super-small {
  width: 30px;

  .global-item-side {
    position: static;
    right: initial;
    top: initial;
    color: $color-lightblue2;
  }
}
.modal-content {
  height: 100%;
}

.modal-full {
  width: 100%;
  height: calc(100vh - 220px);
  max-width: unset;
  max-height: unset;
  .modal-content {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
}
// .modal-lg {
//   width: 70% !important;
//   height: 70%;
//   max-height: 500px;
//   max-width: 1000px !important;
// }

.nav-link {
  cursor: pointer;
  margin-bottom: 0 !important;
  outline: none !important;
  h5 {
    margin-bottom: 0 !important;
    outline: none !important;
    color: black !important;
  }
}

.layer.selected {
  border: 1px dotted black !important;
}
.element.true {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px dotted black !important;
}

.shadow {
  border: none;
  border-radius: 35;
  background: #fff;
  margin-bottom: 40px;
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.sortable-header {
  display: none;
}
.sortable-item {
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  cursor: grab;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #adadad;
}

.sortable-item-active {
  background-color: #e6e6e6;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.page-title {
  float: left;
  margin: 5px;
}
.sortable-wrapper {
  min-height: 150px;
}

.btn {
  margin: 1px;
}

.action-row {
  min-height: 40px;
  font-size: 11px;
}

.pre-scrollable-fluid {
  /* max-height: 340px; */
  overflow-y: scroll;
}
.lobby-pad {
  width: 100%;
  height: 100%;
  padding: 15px;
}
.fullsize {
  width: 100%;
  height: 100%;
}

// .fixed-header {
//   position: fixed;
//   top: 35px;
//   left: 145px;
// }

.canvas-bg {
  height: 100%;
  width: 100%;
  .label {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, 5);
    padding: 2px;
  }
}

body {
  font-family: 'Montserrat', 'Roboto', sans-serif;
}

p {
  font-family: 'Montserrat', 'Roboto', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: $color-darkblue2;
  background: linear-gradient(90deg, $color-darkblue2 0%, $color-darkblue1 100%);
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .sidebar-header .language-bar,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

// #sidebar ul li a {
//     text-align: left;
// }

#sidebar.active ul li a {
  padding-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}

#sidebar.active ul li a i {
  margin-top: 10px;
  margin-right: 0;
  display: block;
  font-size: 2.2em;
  line-height: 0px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

// #sidebar.active .dropdown-toggle::after {
//     top: auto;
//     bottom: 10px;
//     right: 50%;
//     -webkit-transform: translateX(50%);
//     -ms-transform: translateX(50%);
//     transform: translateX(50%);
// }

#sidebar .sidebar-header {
  padding: 20px;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul li a {
  padding: 10px;
  padding-bottom: 0px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: $color-darkblue1;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background-color: argb(0, 255, 255, 50);
}

a[data-toggle='collapse'] {
  position: relative;
}

// .dropdown-toggle::after {
//     display: block;
//     position: absolute;
//     top: 50%;
//     right: 20px;
//     transform: translateY(-50%);
// }

ul ul a {
  padding-left: 30px !important;
  background-color: argb(0, 255, 255, 50);
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content-bg {
  background-color: $color-gray;
}
#content {
  width: 100%;
  // background: $color-gray;

  min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
    background: rgb(220, 223, 237);
    background: linear-gradient(90deg, rgba(220, 223, 237, 1) 0%, rgba(238, 241, 255, 0.6337885495995272) 100%);
    color: #536099;
  }
  // .dropdown-toggle::after {
  //     top: auto;
  //     bottom: 10px;
  //     right: 50%;
  //     -webkit-transform: translateX(50%);
  //     -ms-transform: translateX(50%);
  //     transform: translateX(50%);
  // }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

dialog::backdrop {
  background: rgba(119, 116, 116, 0.25);
}

dialog {
  border: 0;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.footer {
  &.closed {
    height: 15px;
  }
  &.opened {
    height: 150px;
  }
  font-size: 11px;
  color: white;
  width: 100%;
  position: fixed;
  bottom: 0;

  pointer-events: none;
  z-index: 0;

  background-color: $color-lightblue1;
}
.lobby {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.lobby > div {
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
}

.selected {
  border: 1px dotted black !important;
}

.rtl {
  .lobby > div {
    margin-left: 16px;
  }
  .direction {
    float: right;
  }
  .direction-invert {
    float: left;
  }
  .fancy-thumb-label {
    display: block;
    top: 0;
    right: 0;
    color: white;
    background-color: rgba(0, 0, 0, 5);
    padding: 2px;
    position: absolute;
    z-index: 100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fancy-thumb-label.bottom {
    bottom: 0;
    top: unset !important;
  }

  // .input-group > .form-control:not(:first-child),
  // .input-group > .custom-select:not(:first-child) {
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   border-top-left-radius: 4px;
  //   border-bottom-left-radius: 4px;
  // }

  .modal {
    margin: auto;
    width: 50%;
    height: 50%;
  }

  #scroller {
    height: calc(100% - 160px);
    overflow-x: auto;
  }
  p {
    text-align: right;
    direction: rtl;
  }
  div {
    text-align: right;
    // direction:rtl;
  }
  label {
    width: 100%;
    text-align: right;
    direction: rtl;
  }
  .mr-auto {
    width: 100%;
    li {
      display: inline;
      list-style-type: none;
      padding-right: 20px;
    }
  }

  .slider-container {
    overflow: auto;
    max-width: 0;
    padding: 15px;
    // position: fixed;
    // top:54px;
    // bottom: 0px;
    // left: -50%;
  }
  .slider-container-drawer,
  .slider-container-wide {
    display: none;
    z-index: 1500;
    max-width: 0;
    padding: 15px;

    position: absolute;
    top: 55px;
    bottom: 0;
    left: -50%;
  }

  .slider-container.active {
    overflow: auto;
    width: 100%;
    max-width: 500px;
    left: 0;
    overflow-x: hidden;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slider-container.closing {
    left: -50px;
    overflow-x: hidden;
    width: 0%;
    max-width: 0px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slider-container-drawer.active,
  .slider-container-wide.active {
    width: 100%;
    display: block;
    max-width: 900px;
    left: 0;
    overflow: hidden;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }
}

.no-margin {
  margin: 0;
  padding: 0;
}

.scroller {
  height: calc(100% - 57px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ltr {
  .direction {
    float: right;
    .btn-long {
      opacity: 0.6;
      font-size: 24px;
      text-align: left;
      vertical-align: middle;
      cursor: pointer;
    }
    .direction-invert {
      float: left;
    }
    .lobby > div {
      margin-right: 16px;
    }

    .fancy-thumb-label {
      top: 0;
      left: 0;
      color: white;
      background-color: rgba(0, 0, 0, 5);
      padding: 2px;
      position: absolute;
      z-index: 100;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fancy-thumb-label.bottom {
      bottom: 0;
      top: unset !important;
    }

    // .close-button
    // {
    //     color: white;
    //     cursor: pointer;
    //     opacity: 0.5;
    //     float: right;
    // }
    // .close-button:active,
    // .close-button:hover
    // {
    //     opacity: 1;

    // }

    #scroller {
      height: calc(100% - 160px);
      overflow-x: auto;
    }
    .slider-container {
      overflow: auto;
      // max-width: 0;
      padding: 15px;

      // position: fixed;
      // top:54px;
      // bottom: -22px;
      // right: -50%;
    }
    .slider-container-drawer,
    .slider-container-wide {
      z-index: 1500;
      max-width: 0;
      padding: 15px;
      display: none;
      position: absolute;
      top: 55px;
      bottom: 0;
      right: -50%;
    }
    .slider-container-drawer.active,
    .slider-container-wide.active {
      display: block;
      width: 100%;
      max-width: 900px;
      right: 0;
      overflow: hidden;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .slider-container.active {
      right: 0;
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      max-width: 500px;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slider-container.closing {
      right: -50px;
      overflow-x: hidden;
      width: 0%;
      max-width: 0px;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .mr-auto li {
      display: inline;
      list-style-type: none;
      padding-right: 20px;
    }
  }

  // .input-group > .form-control:not(:first-child),
  // .input-group > .custom-select:not(:first-child) {
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  //   border-top-right-radius: 4px;
  //   border-bottom-right-radius: 4px;
  // }
}

.sqr {
  width: 230px;
  height: 150px;
  margin: 5px;
  padding: 5px;
}

.sqr.small {
  width: 150px;
  height: 80px;
  margin: 5px;
}

.rtl {
  .btn-long {
    opacity: 0.6;
    font-size: 24px;
    text-align: right;
    vertical-align: middle;
    cursor: pointer;
  }
}
.btn-long:hover {
  opacity: 1;
}

.dashboard-nav {
  padding: 8px;
  height: 50px;
  border: none;

  background: #e3e4e5;
}

.btn-md {
  padding: 3px 3px;
  font-size: 14px;
  line-height: 1;
  border-radius: 0.2rem;
}

.sqr {
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  overflow: hidden;
}

.sqr-btn:hover,
.sqr:hover {
  opacity: 1;
  transform: translate(2px, 2px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) !important;

  .sqr-toolbar {
    border: 0;
    visibility: visible;
  }
}
.sqr-toolbar {
  padding: 3px;
  border-radius: 0;
}
.sqr-btn._meta_active,
.sqr._meta_active {
  opacity: 1;

  transform: translate(2px, 2px);
  border: 1px solid rgba(69, 59, 59, 0.2);
  border-radius: 35;
  background: #fff;

  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) !important;

  .sqr-toolbar {
    visibility: visible !important;
  }
}

.sqr-toolbar {
  li {
    width: 50%;
  }
}
.ltr {
  .sqr-toolbar {
    direction: rtl;
  }
}

.rtl {
  .sqr-toolbar {
    direction: ltr;
  }
}

.sqr-toolbar {
  z-index: 2;
  visibility: hidden;
  position: absolute;
  opacity: 0.8;
  left: 0px;
  right: 0px;
  bottom: -16px;
  direction: rtl;
  background-color: black;
  color: white;
  font-size: 8px;
  padding: 3px;
}

.btn-spinner-host {
  position: relative;
  padding-right: 20px;
}
@keyframes btn-spinner {
  to {
    transform: rotate(360deg);
  }
}

.btn-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 95%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: btn-spinner 0.6s linear infinite;
}

a.question-cat {
  color: $color-question;
}
div.question-cat,
li.question-cat {
  color: $color-question;
  background: $color-darkblue1;
  background: linear-gradient(90deg, $color-darkblue1 0%, $color-darkblue2 99%);
}

a.question-group-cat {
  color: $color-question-group;
}

div.question-group-cat,
li.question-group-cat {
  color: $color-black;
  background: $color-darkblue1;
  background: linear-gradient(90deg, $color-brown 0%, $color-graywhite 99%);
}

a.library-cat {
  color: $color-library;
}

div.library-cat {
  color: $color-library;
  background: $color-darkblue1;
  background: linear-gradient(90deg, $color-darkblue1 0%, $color-darkblue2 99%);
}

li.library-cat {
  color: $color-library;
  background: $color-lightblue1;
  background: linear-gradient(90deg, $color-lightblue1 0%, $color-lightblue2 99%);
}
a.playlist-cat {
  color: $color-purple;
}

div.playlist-cat {
  color: $color-purple;
  background: $color-darkblue1;
  background: linear-gradient(90deg, $color-darkblue1 0%, $color-darkblue2 99%);
}

li.playlist-cat {
  color: $color-purple;
  background: $color-lightblue1;
  background: linear-gradient(90deg, $color-lightblue1 0%, $color-lightblue2 99%);
}

.playlist {
  .topper {
    position: static !important;
  }
}

.card-header {
  background-color: $color-darkblue1;
  color: $color-gray;
  border-radius: 0 !important;
  padding: 1px !important;
  margin: 1px !important;
}
.card {
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.filter-slide {
  border: 1px solid #e3e4e5;
  border-radius: 5px;
  background-color: #1d3045;
  position: absolute;
  top: 115px;

  z-index: 100;

  bottom: 30px;
  display: none;
  padding: 10px;

  .form-group,
  .input-group {
    label {
      color: white;
    }
  }
}

.filter-slide.closing {
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.filter-slide.active {
  display: block;
  position: absolute;
  bottom: 30px;
  width: 250px;
  overflow-x: hidden;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.rtl {
  .filter-slide {
    left: 0px;
  }
  .filter-slide.active {
    left: 25px;
  }
}

.ltr {
  .filter-slide {
    right: 0px;
  }

  .filter-slide.active {
    right: 25px;
  }
}

.joyride-step__body {
  white-space: pre;
}

.joyride-backdrop,
.backdrop-container {
  display: none !important;
}

.conditions-table {
  th {
    background-color: rgb(224, 215, 215);
    font-size: 12px;
    font-weight: bold;
  }
  .logic-select {
    min-width: 60px;
  }
}
.form-control-xs {
  font-size: 11px;
  height: 25px;
  margin: 0;
  padding: 5px;
}
.logic-operator {
  width: 50px;
  font-weight: bold;
  font-family: 'cursive';
  background-color: rgb(176, 241, 228);
}
.read-line {
  font-size: 11px;
}
.btn-pad {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.sticky-form-header {
  position: sticky;
  top: 0;
  z-index: 1001;
}

.paint-form {
  .form-group {
    padding: 3px;
    border: 1px solid #e0e1db;
    border-radius: 3px;
    background-color: #e0e1db;
    .text-muted {
      color: #778833 !important;
    }
  }

  .form-group:focus {
    background-color: #000000;
  }

  .form-group:hover {
    border: 1px solid #878882;
    background-color: #878882;
    .text-muted {
      color: #ffffff !important;
    }
    label {
      color: #ffffff !important;
    }
  }
}

.btn-outline {
  border: 1px solid #9d8f8f;
  background-color: #9d8f8f;
}

.btn-outline:hover {
  border: 1px solid #9d8f8f;
  background-color: transparent !important;
}

.btn-xs {
  height: 22px;
  padding: 1px 3px;
  font-size: 11px;
  line-height: 1;
}

.head-small {
  padding: 0;
  background-color: #eee;
  color: #333;
  font-size: 12px;
  font-weight: 700;
  .btn-group {
    width: 100%;
    margin: 0;
  }
  .btn {
    margin: 0;
  }
}

.condition-block {
  table {
    margin: 0;
  }
  legend {
    margin: 0;
  }
  border: 1px solid #acc9ac;
  border-radius: 5px;
  margin: 5px;
}

.product-codes {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    font-size: 10px;
    background-color: #000000;
    color: #fff;
    padding-left: 5px;
    margin: 3px;
    border-radius: 10px;
  }
}
