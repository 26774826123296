
$color-gray: #e3e4e5;
$color-darkblue1: #162433;
$color-darkblue2: #2a4563;
$color-lightblue1: #264356;
$color-lightblue2: #3c7399;
$color-orange: #fc6600;
$color-cyan: #00fff6;
$color-question: #00cfff;
$color-question-group: #00fff6;
$color-library: #96ff00;
$color-yellow: #ffff00;
$color-brown: #9d8f8f;
$color-brownlight: #c6b2b2;
$color-browndark: #6a6161;


$color-purple: #632a62;


$color-green: #96ff00;
$color-black: #222;
$color-graywhite: #FFE;
$color-toolbar: #f8f9fa;




